@use "../../../../../styles/variables.module" as *;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  height: 7em;
  width: 7em;
  margin: 10% 0 10% 0;
}

.staffName {
  font-weight: bold;
  text-align: center;
}

.staffTitle {
  text-align: center;
}

@media(max-width: $break-point-sm) {
  .imageContainer {
    height: 5em;
    width: 5em;
  }
}

@media(max-width: 300px) {
  .imageContainer {
    height: 4em;
    width: 4em;
  }

  .staffName {
    font-size: 12px;
  }

  .staffTitle {
    font-size: 12px;
  }
}
